import Vue from 'vue'
import App from "./App.vue";

import store from "./store";
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from "./router";
import ErrorFilter from "./common/error.filter";
import { CHECK_AUTH } from "./store/store.types";
import ApiService from "./common/api.service";
import VuePrototypeExtensions from "./common/vue.extensions";

Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultContainerElement: '.container',
});

Vue.filter("error", ErrorFilter);

VuePrototypeExtensions.install(Vue);

ApiService.init();


new Vue({
    store,
    router,
    render: h => h(App)
 }).$mount('#app')