import {
    FETCH_PROVIDERS, ISSUES_LOADING_END, ISSUES_LOADING_START
} from "./store.types";
import ApiService  from "../common/api.service"

const state = {
    providers: [],
    isLoading: true,
    loadingCounter: 0,
};

const getters = {
    providersCount(state) {
        return state.providers ? state.providers.length : 0;
    },
    providers(state) {
        return state.providers;
    },
    isLoading(state) {
        return state.isLoading;
    }
};

const actions = {
    [FETCH_PROVIDERS]({ commit }, params) {
        commit(ISSUES_LOADING_START);
        return ApiService.postMessage("GETPROVIDERS", null)
            .then(( response ) => {
                //commit(PROVIDERS_FETCH_END, response.data && response.data.DATA ? response.data.DATA : []);
                //commit(ISSUES_LOADING_END, []);
            })
            .catch(error => {
                //commit(ISSUES_LOADING_END, []);
                //commit(ADD_ERROR, {"FETCH_PROVIDERS_ERROR": error});
                //throw new Error(error);
            });
    },
};

const mutations = {
    [ISSUES_LOADING_START](state) {
        state.loadingCounter += 1;
        state.isLoading = true;
    },
    [ISSUES_LOADING_END](state) {
        state.loadingCounter -= 1;
        if(state.loadingCounter < 1)
            state.isLoading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};